import React from "react";
import { Link } from "react-router-dom";

function Dropdown({ isOpenSmMenu, toggleSmMenu }) {
  return (
    <div
      className={isOpenSmMenu ? "absolute z-10 w-full " : "hidden"}
      onClick={toggleSmMenu}
    >
      {/* <div className="dropdown-item ">
        <Link to="/admin">Dashboard</Link>
      </div> */}

      <DropdownItem name={"Dashboard"} link={"/admin"} />

      <DropdownItem name="Trips" link="/admin/trips" />

      <DropdownItem name={"Buses"} link={"/admin/buses"} />

      {/* <DropdownItem name={"GTFS Data"} link={"/admin/gtfsdata"} /> */}
      <DropdownItem name={"Profile"} link={"/admin/profile"} />
      <DropdownItem name={"Settings"} link={"/admin/settings"} />
      <DropdownItem name={"Sign Out"} link={"#"} />
    </div>
  );
}

function DropdownItem({ name = "", link = "#" }) {
  return (
    <div className="dropdown-item">
      <Link to={link}>{name}</Link>
    </div>
  );
}

export default Dropdown;

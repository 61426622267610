import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormFieldItem from "../../components/FormFieldItem";

function Profile() {
  const initialValues = {
    name: "",
    email: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required: Name field cannot be empty"),
    email: Yup.string().required("Required: email cannot be empty"),
    email: Yup.string().email("Invalid email"),
  });

  const onSubmit = (values) => {
    console.log("Form data: ", values);
  };

  return (
    <div className="pages-content">
      <div className="flex max-w-sm content-center bg-primary p-8 shadow-lg">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className="space-y-3">
            <FormFieldItem
              id="name"
              name="name"
              type="text"
              label={"Full Name"}
              placeholder={"Full Name"}
            />
            <FormFieldItem
              id="email"
              name="email"
              type={"email"}
              label={"e-mail"}
              placeholder={"e-mail"}
            />
            <FormFieldItem
              id="mobile"
              name="mobile"
              type={"text"}
              label={"Contact"}
              placeholder={"Contact Number"}
            />
            <div className="flex content-center justify-center">
              <input
                name="submit"
                type="submit"
                value={"Update Details"}
                className="round bg-secondary py-1 px-4 shadow shadow-gray-500"
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default Profile;

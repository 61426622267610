import React from "react";
import { useTable } from "react-table";
import SearchBar from "../../components/SearchBar";
import SecondaryNav from "../../components/SecondaryNav";

function Trips() {
  return (
    <div className="page-main">
      {/* <SearchBar
        label="Search Trips"
        placeholder="Eg: Bus Name/Bus Id/Destination/Time etc."
      /> */}

      <SecondaryNav searchPlaceholder="Search in Trips" SearchLabel=" " />
      <TripsTable />
    </div>
  );
}

export default Trips;

// Page Components

function TripsTable() {
  const data = React.useMemo(
    () => [
      {
        slNo: "1",
        regnNo: "KL 15 1234",
        busId: "R1C3",
        busName: "KSRTC",
        tripOrigin: "Munnar",
        arrival: "06:00 am",
        departure: "06:15 am",
        destination: "Ernakulam",
        destinationETR: "04:00 pm",
        arrivalAtKmgm: "10:00 am",
      },
      {
        slNo: "2",
        regnNo: "KL 15 5678",
        busId: "R2C3",
        busName: "KSRTC",
        tripOrigin: "Kothamangalam",
        arrival: "10:00 am",
        departure: "10:05 am",
        destination: "Aluva",
        destinationETR: "11:30 am",
        arrivalAtKmgm: "10:00 am",
      },
      {
        slNo: "3",
        regnNo: "KL 15 9012",
        busId: "R3C3",
        busName: "KSRTC",
        tripOrigin: "Pothanicad",
        arrival: "09:55 am",
        departure: "10:00 am",
        destination: "Perumbavoor",
        destinationETR: "11:00 am",
        arrivalAtKmgm: "10:25 am",
      },
    ],
    []
  );
  const columns = React.useMemo(
    () => [
      {
        Header: "Sl. No.",
        accessor: "slNo",
      },
      {
        Header: "Regn. No.",
        accessor: "regnNo",
      },
      {
        Header: "Bus Id",
        accessor: "busId",
      },
      {
        Header: "BusName",
        accessor: "busName",
      },
      {
        Header: "Trip Origin",
        accessor: "tripOrigin",
      },
      {
        Header: "Arrival",
        accessor: "arrival",
      },
      {
        Header: "Departure",
        accessor: "departure",
      },
      {
        Header: "Destination",
        accessor: "destination",
      },
      {
        Header: "Destination ETR",
        accessor: "destinationETR",
      },
      {
        Header: "Arrival at KMGM",
        accessor: "arrivalAtKmgm",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    // apply the table props
    <div className="overflow-scroll overflow-y-scroll rounded-sm shadow-md shadow-plight">
      <table className="w-full border-b-2 border-gray-400" {...getTableProps()}>
        <thead className="border-b-2 border-gray-200 bg-secondary">
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      className="p-3 text-left text-sm font-semibold tracking-wide"
                      {...column.getHeaderProps()}
                    >
                      {
                        // Render the header
                        column.render("Header")
                      }
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody
          className="border-b-2 border-gray-50 bg-gray-400 shadow"
          {...getTableBodyProps()}
        >
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr
                  className="border-b-2 border-gray-50"
                  {...row.getRowProps()}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td
                          className="p-3 text-sm text-gray-900"
                          {...cell.getCellProps()}
                        >
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

import React from "react";

import { BsGear, BsInfoCircle, BsQuestionCircle } from "react-icons/bs";

function MobileSideBar({ isOpenMobSidebar, toggleMobSidebar }) {
  return (
    <div
      className={
        isOpenMobSidebar
          ? "mobile-sidebar-main translate-x-0 duration-500"
          : "mobile-sidebar-main -translate-x-96 duration-500"
      }
    >
      <div>
        <div
          name="SideBarHead"
          id="SideBarHead"
          className="flex h-24 items-center border-b-2 border-white px-2 py-2 text-lg font-bold text-white"
        >
          TransitFeeds<span className="ml-1 text-xs">India</span>
        </div>
        <div onClick={toggleMobSidebar}>
          <MenuItem icon={<BsGear />} text={"Settings"} />
          <MenuItem icon={<BsQuestionCircle />} text={"Help and feedback"} />
          <MenuItem icon={<BsInfoCircle />} text={"About"} />
        </div>
      </div>
    </div>
  );
}

export default MobileSideBar;

function MenuItem({ icon, text, linkTo = "#" }) {
  return (
    <div className="flex h-12 items-center space-x-2 p-2 text-white hover:bg-secondary hover:text-black">
      <div className="w-4 ">{icon}</div>
      <div>{text}</div>
    </div>
  );
}

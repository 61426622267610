import React from "react";

function Dashboard() {
  return (
    <div className="pages-content">
      <h1>This is Dashboard</h1>

      <div className="h-28 w-full bg-violet-900 "> filler</div>
    </div>
  );
}

export default Dashboard;

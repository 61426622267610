import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function FormFieldItem({ id, name, type = "text", placeholder, label }) {
  return (
    <div className="w-full">
      <label htmlFor={name} className="text-sm text-white">
        {label}
      </label>
      <div className="text-orange-500">
        <ErrorMessage name={name} />
      </div>
      <Field
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        className="w-full p-1"
      />
    </div>
  );
}

export default FormFieldItem;

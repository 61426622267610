import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function LogInForm() {
  const initialValues = {
    username: "",
    password: "",
    rememberme: false,
  };

  const onSubmit = (values) => {
    console.log("Form data: ", values);
  };

  // const validate = (values) => {
  //   let errors = {};
  //   if (!values.username) {
  //     errors.username = "Required: Username/e-mail cannot be empty";
  //   }
  //   if (!values.password) {
  //     errors.password = "Required: Password cannot be empty";
  //   }
  //   console.log("validation errors:", errors);
  //   return errors;
  // };

  const validationSchema = Yup.object({
    username: Yup.string().required(
      "Required: Username/e-mail field cannot be empty"
    ),
    password: Yup.string().required("Required: Password field cannot be empty"),
  });

  // const formik = useFormik({
  //   initialValues,
  //   onSubmit,
  //   validationSchema,
  // });

  return (
    <div className="w-96 content-center bg-primary p-8 shadow-lg">
      <h1 className="mb-5 justify-center text-center text-xl font-semibold text-white">
        Login to your account
      </h1>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className="space-y-3">
          <div>
            <label htmlFor="username" className="block text-white">
              Username/e-mail
            </label>
            <div className="text-sm text-red-500">
              <ErrorMessage name="username" />
            </div>

            <Field
              id="username"
              name="username"
              type={"text"}
              placeholder="Enter account username/e-mail"
              className="input-form-text p-1 text-lg"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-white">
              Password
            </label>
            <div className="text-sm text-red-500">
              <ErrorMessage name="password" />
            </div>

            <Field
              id="password"
              name="password"
              type={"password"}
              placeholder="Enter account password"
              className="input-form-text p-1 text-lg"
            />
          </div>
          <div>
            <label htmlFor="rememberme" className="text-white ">
              <Field
                id="rememberme"
                name="rememberme"
                type={"checkbox"}
                className="lg mr-2 accent-slight"
              />
              Remember me on this device
            </label>
          </div>
          <div className="flex content-center justify-center">
            <input
              name="submit"
              type="submit"
              value={"Log in"}
              className="round bg-secondary py-1 px-4 shadow shadow-gray-500"
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export default LogInForm;

import React from "react";

function SearchBar({
  label = "Search",
  placeholder = "Search keywords",
  formMethod = "GET",
  formAction = "#",
}) {
  return (
    <div className="mb-5 flex items-center justify-center border-gray-900 bg-primary p-6 shadow">
      <div className="flex  content-center justify-center">
        <form method={formMethod} action={formAction}>
          <label className="mx-2 text-xl font-semibold  text-white">
            {label}
            <input
              type={"text"}
              name="searchItem"
              placeholder={placeholder}
              className={
                "sm"
                  ? "mt-2 ml-2 rounded p-1 text-gray-700 shadow placeholder:text-sm placeholder:text-secondary"
                  : "ml-2 rounded p-1 text-gray-700 shadow placeholder:text-sm placeholder:text-secondary"
              }
            />
          </label>

          <input
            type={"submit"}
            value="Search"
            className="text-md m-2 cursor-pointer rounded bg-secondary py-2 px-5 text-black
          shadow transition-all duration-300 ease-linear
          hover:bg-slight"
          />
        </form>
      </div>
    </div>
  );
}

export default SearchBar;

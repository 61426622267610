import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormFieldItem from "./FormFieldItem";

function AddBusForm() {
  const initialValues = {
    RegnNo: "",
    BusId: "",
    BusName: "",
    Agency: "",
    ServiceType: "Ordinary",
    ServiceStatus: true,
  };
  const validationSchema = Yup.object({
    RegnNo: Yup.string().required("Required: Regn. No field cannot be empty"),
    BusName: Yup.string().required("Required: Bus name field cannot be empty"),
    Agency: Yup.string().required("Required: Bus should be under an agency"),
  });
  const onSubmit = (values) => {
    console.log("Form data: ", values);
  };
  return (
    <div className="page-main flex items-center justify-center">
      <div className="w-full max-w-screen-sm content-center space-y-2 rounded bg-primary p-3 shadow-md shadow-black">
        <div className="border-b-2 border-white py-4 text-center">
          <h1 className="text-2xl font-extrabold text-white">
            Add new bus details
          </h1>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form className="space-y-3">
            <FormFieldItem
              id="RegnNo"
              name="RegnNo"
              type={"text"}
              label={"Regn. No."}
              placeholder={"Registration Number"}
            />
            <FormFieldItem
              id="BusId"
              name="BusId"
              type={"text"}
              label={"Bus id"}
              placeholder={"Bus unique id"}
            />

            <FormFieldItem
              id="BusName"
              name="BusName"
              type={"text"}
              label={"Bus Name"}
              placeholder={"Bus Name"}
            />
            <FormFieldItem
              id="Agency"
              name="Agency"
              type={"text"}
              label={"Agency/Depot"}
              placeholder={"Bus Agency/Depot"}
            />
            <FormFieldItem
              id="ServiceType"
              name="ServiceType"
              type={"text"}
              label={"Service Type"}
              placeholder={"Service Type"}
            />
            <div>
              <label htmlFor="rememberme" className="text-white ">
                <Field
                  id="ServiceStatus"
                  name="ServiceStatus"
                  type={"checkbox"}
                  className="lg mr-2 accent-slight"
                />
                Service Status
              </label>
            </div>
            <div className="flex content-center justify-center">
              <input
                name="submit"
                type="submit"
                value={"Save"}
                className="round bg-secondary py-1 px-4 shadow shadow-gray-500"
              />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default AddBusForm;

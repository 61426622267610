import React from "react";
import SideBar from "../components/Sidebar";
import Buses from "./Admin/Buses";
import Dashboard from "./Admin/Dashboard";
import GtfsData from "./Admin/GtfsData";
import Profile from "./Admin/Profile";
import Settings from "./Admin/Settings";
import Trips from "./Admin/Trips";

import { Route, Routes } from "react-router-dom";
import Dropdown from "../components/Dropdown";
import MobileNav from "../components/MobileNav";
import AddBusForm from "../components/AddBusForm";

function Content({ isOpenMobSidebar, toggleMobSidebar }) {
  let clickEvnt = null;
  if (isOpenMobSidebar) {
    clickEvnt = toggleMobSidebar;
  } else {
    clickEvnt = null;
  }
  return (
    <div className="flex w-full " onClick={clickEvnt}>
      {/* <SideBar /> */}

      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="/admin" element={<Dashboard />} />
        <Route path="/trips" element={<Trips />} />
        <Route path="/buses" element={<Buses />} />
        <Route path="/addbus" element={<AddBusForm />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/Settings" element={<Settings />} />
        <Route
          path="*"
          element={() => (
            <>
              <div className="py-96">
                <h1>404 Error</h1>
              </div>
            </>
          )}
        />
      </Routes>
    </div>
  );
}

export default Content;

import React from "react";

function Settings() {
  return (
    <div className="pages-content">
      <h1>Page Settings</h1>
    </div>
  );
}

export default Settings;

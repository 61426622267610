import Dropdown from "./components/Dropdown";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import react, { useState, useEffect } from "react";
import Content from "./Pages/Content";
import MobileNav from "./components/MobileNav";
import MobileFooter from "./components/MobileFooter";
import MobileSideBar from "./components/MobileSideBar";

function App() {
  const [isOpenSmMenu, setIsOpenSmMenu] = useState(false);

  const [navbarState, setNavBarState] = useState("Home");

  const [hideMobileNav, setHideMobileNav] = useState(false);

  const [isOpenMobSidebar, setIsOpenMobSidebar] = useState(false);

  const toggleMobSidebar = () => {
    setIsOpenMobSidebar(!isOpenMobSidebar);
  };
  const toggleSmMenu = () => {
    setIsOpenSmMenu(!isOpenSmMenu);
  };

  var lastScrollPosition = 0;

  useEffect(() => {
    const hideSmMenu = () => {
      if (window.innerWidth > 768 && isOpenSmMenu) {
        setIsOpenSmMenu(false);
        console.log("Window resized");
      }
    };
    window.addEventListener("resize", hideSmMenu);

    const hideMobileNavBar = () => {
      // console.log("Scroll Direction: ", lastScrollPosition - window.scrollY);
      if (lastScrollPosition - window.scrollY < 0 && window.scrollY > 33) {
        setHideMobileNav(true);
      } else {
        setHideMobileNav(false);
      }
      lastScrollPosition = window.scrollY;
    };

    window.addEventListener("scroll", hideMobileNavBar);

    return () => {
      window.removeEventListener("resize", hideSmMenu);
    };
  }, []);

  return (
    <>
      <Navbar
        isOpenSmMenu={isOpenSmMenu}
        toggleSmMenu={toggleSmMenu}
        navbarState={navbarState}
        setNavBarState={setNavBarState}
      />
      <Dropdown isOpenSmMenu={isOpenSmMenu} toggleSmMenu={toggleSmMenu} />

      <div>
        <div className="">
          <MobileNav
            hideNav={hideMobileNav}
            toggleMobSidebar={toggleMobSidebar}
          />
          <MobileSideBar
            isOpenMobSidebar={isOpenMobSidebar}
            toggleMobSidebar={toggleMobSidebar}
          />
        </div>
        <Content
          isOpenMobSidebar={isOpenMobSidebar}
          toggleMobSidebar={toggleMobSidebar}
        />
        <div className="">
          <MobileFooter hideButton={hideMobileNav} />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;

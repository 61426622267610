import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { BsGraphUp, BsSignpostSplit } from "react-icons/bs";
import { FaBus } from "react-icons/fa";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

function MobileFooter({ hideButton, buttonLabel = "Add New" }) {
  return (
    <div className="relative bottom-0 left-0 h-36 min-w-full bg-pdark text-black md:hidden">
      <div className="fixed left-0 bottom-0 h-16 min-w-full bg-plight">
        <div className=" absolute right-0 -top-16 mr-5 flex max-w-max space-x-2 rounded-md bg-plight p-3 text-base text-white shadow-sm shadow-black">
          <FaPlus className="text-lg font-bold" />
          <div className={hideButton ? "hidden" : "block"}>{buttonLabel}</div>
        </div>
        <div className="-shadow-sm flex min-w-full justify-around p-2 text-sm text-white shadow-black">
          <FooterIcon
            icon={<BsGraphUp className="text-lg font-bold" />}
            iconLabel={"Dashboard"}
            linkTo="/admin"
          />
          <FooterIcon
            icon={<BsSignpostSplit className="text-lg font-bold" />}
            iconLabel={"Trips"}
            linkTo="/admin/trips"
          />
          <FooterIcon
            icon={<FaBus className="text-lg font-bold" />}
            iconLabel={"Buses"}
            linkTo="/admin/buses"
          />
        </div>
      </div>
    </div>
  );
}

export default MobileFooter;

function FooterIcon({ icon, iconLabel = "", linkTo }) {
  let resolved = useResolvedPath(linkTo);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <Link to={linkTo}>
      <div className="grid grid-cols-1 place-items-center">
        <div
          className={
            match
              ? "rounded-lg bg-blue-500 py-1 px-3  text-base font-semibold text-white"
              : "rounded-lg py-1 px-3 text-base  font-semibold text-white"
          }
        >
          {icon}
        </div>
        <div className="text-base text-white">{iconLabel}</div>
      </div>
    </Link>
  );
}

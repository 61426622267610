import React from "react";
import { BsGeoAlt, BsFillMenuButtonWideFill } from "react-icons/bs";
import { FaMapMarked } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { Link, Outlet } from "react-router-dom";

export default function Navbar({ toggleSmMenu }) {
  return (
    <>
      <nav className="hidden w-full bg-pdark shadow-md md:block">
        <div id="main-container" className="mx-auto px-8">
          <div id="nav-items-container" className="flex justify-between py-2">
            <div id="primary-nav-group" className="flex space-x-4">
              {/* logo */}
              <Link
                to="/"
                id="main-logo-container"
                className="flex items-center text-white"
              >
                <FaMapMarked className="mr-2 text-3xl text-green-500" />
                <span className="text-xl font-bold md:text-3xl">
                  TransitFeeds
                </span>
                India
              </Link>
              {/* Primary Nav */}
              <div
                id="primary-nav"
                className="hidden items-center space-x-1 text-xl text-white md:flex"
              >
                <Link to="/admin/buses" className="py-4 px-2 ">
                  Contacts
                </Link>
                <div className="py-4 px-2 ">PN Item2</div>
              </div>
            </div>

            {/* Secondary Nav */}
            <div
              id="secondary-nav-container"
              className="hidden items-center space-x-1 text-xl text-white md:flex"
            >
              <div className="py-4 px-2 ">
                <Link to="/login">Login</Link>
              </div>
              <div
                className="cursor-pointer rounded bg-secondary py-2 px-3
            text-black transition-all duration-300 ease-linear
            hover:bg-slight"
              >
                Register
              </div>
            </div>
            {/* Mobile menu button */}
            <div
              className="cursor-pointer; flex items-center rounded-xl py-1 px-1
          text-white transition-all
          duration-300 ease-linear hover:rounded-md hover:bg-secondary hover:text-black
          md:hidden"
              onClick={toggleSmMenu}
            >
              <HiMenu size="28" />
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
      </nav>
      <Outlet />
    </>
  );
}

const NavbarItem = ({ icon, text = "Sample", active = false, style = "" }) => {
  return (
    <div
      name="icon-container"
      className="mr-2 flex cursor-pointer flex-row text-center text-xl font-bold"
    >
      {icon}
      <span className="ml-2">{text}</span>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";

function MobileNav({
  searchText = "Search in trips",
  contentHeading = "Details",
  subHeading = "Service",
  hideNav = false,
  toggleMobSidebar,
}) {
  return (
    <div className="md:hidden">
      <div className="relative top-0 left-0 flex h-24 w-full bg-primary text-sm text-white shadow-sm shadow-black ">
        <div className="content-center px-2 text-base font-semibold">
          TransitFeeds
        </div>
        <div
          className={
            hideNav
              ? "mobile-nav-component -translate-y-24 duration-500"
              : "mobile-nav-component translate-y-8 shadow-sm shadow-black duration-500"
          }
        >
          <div className="ml-2 px-1 text-2xl" onClick={toggleMobSidebar}>
            <HiMenu />
          </div>
          <div className="w-full">
            <input
              type="text"
              placeholder={searchText}
              className="w-full bg-plight py-1 text-sm text-white"
            />
          </div>
          <div className="mx-2 flex items-center justify-center rounded-xl bg-pdark p-1 text-lg  hover:bg-secondary hover:text-black">
            <BsFillPersonFill />
          </div>
        </div>
        <div className="absolute bottom-0 px-2 text-sm text-white">
          <div className="">{contentHeading}</div>
        </div>
      </div>
      {/* 
        <div className="bg-yellow-200 w-full  h-24">Content first</div>
        <div className="bg-teal-200 w-full h-10 "> filler</div> */}
    </div>

    // <div className="min-w-full bg-primary h-10 py-10 bottom-0 left-0 right-0 fixed">
    //   <h1 className="text-white">This isFooter</h1>
    // </div>
  );
}

export default MobileNav;

import React from "react";
import { Link } from "react-router-dom";
import LogInForm from "../components/LogInForm";

function LoginPage() {
  return (
    <div className="page-main flex items-center justify-center ">
      <LogInForm />
    </div>
  );
}

export default LoginPage;

import React from "react";

function Footer() {
  return (
    <footer className=" bottom-0 left-0 w-full bg-plight">
      <div
        id="main-container"
        className="flex h-16 items-center justify-center bg-secondary text-black"
      >
        <p>Copy right 2022 @ theboringrobot.com </p>
      </div>
    </footer>
  );
}

export default Footer;

<p>This is a foote</p>;

import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaPlus } from "react-icons/fa";

function SecondaryNav({
  SearchLabel = "Search",
  searchPlaceholder = "Search keyword",
  addNewLabel = "Add item",
}) {
  const onSubmit = (values) => {
    console.log("Form data: ", values);
  };

  const validationSchema = Yup.object({
    searchKey: Yup.string().required("Type something to search"),
  });
  return (
    <div className="mb-5 hidden w-full rounded bg-primary py-2 shadow md:block">
      <div className="mx-auto px-8">
        <div className="flex items-center justify-between py-2">
          <div
            name="addNewButton"
            id="addNewButton"
            className="ml-2 flex items-center space-x-2 rounded bg-secondary py-1 px-4 shadow shadow-gray-500 hover:bg-slight"
          >
            <FaPlus className="font-extrabold text-gray-900" />
            <span className="ml-2 font-bold text-gray-800">{addNewLabel}</span>
          </div>

          <div>
            <Formik
              initialValues={{ searchKey: "" }}
              onSubmit={onSubmit}
              //   validationSchema={validationSchema}
            >
              <Form>
                <div className="">
                  <label
                    htmlFor="searchKey"
                    className="px-2 text-lg font-bold text-white"
                  >
                    {SearchLabel}
                  </label>
                  <Field
                    id="searchKey"
                    name="searchKey"
                    type={"text"}
                    placeholder={searchPlaceholder}
                    className={
                      "rounded px-2 py-1 text-gray-500 placeholder:text-sm placeholder:text-secondary"
                    }
                  />
                  <input
                    type={"submit"}
                    value={"Search"}
                    className="ml-2 rounded bg-secondary py-1 px-4 shadow shadow-gray-500 hover:bg-slight"
                  />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SecondaryNav;
